import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";


const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Professional Coder.", "Full Stack Developer.", "UI Designer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    
      <div className="w-full mx-3 lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
      <h2 className='text-2xl  text-slate-300 uppercase text-center'>NEW LIFE ENGINEERING</h2>
        {/* <h1 className=" text-lg font-normal">NEW LIFE ENGINEERING</h1> */}
        
        <h4 className=" text-lg font-normal">VISION</h4>
        <ul>
          <li>To transform our business into a global company in offshore & marine and environmental technology.</li>
          <br></br>
          <li>To enhance our status as a leading offshore & onshore fabricator.</li>
        </ul>
                
        <h4 className=" text-lg font-normal">MISSION</h4>
        <ul>
          <li>To intensify our R&D with more cutting edge and patented technologies in order to penetrate new market sectors and new industries leveraging on our technologies.</li>
          <br></br>
          <li>To increase sales turnover regionally and globally by multiple times in next few years.</li>
        </ul>
        <h4 className=" text-lg font-normal">CORE VALUES</h4>
        <ul>
          <li>To deliver on commitments to clients as we have been doing over the past 9 years.</li>
          <br></br>
          <li>To serve with excellence, innovation, integrity, honesty and hard work.</li>
          <br></br>
          <li>To grow together with clients in terms of repeat orders, synthesizing strengths and ideas from both sides.</li>
        </ul>
      </div>

    </div>
    
  );
}

export default LeftBanner