import React from 'react'
import Title from '../layouts/Title'
import { facilitytOne, facilityTwo, facilityThree, facilityFour, facilityFive, facilitySix } from "../../assets/index";
import ProjectsCard from '../projects/ProjectsCard';

const Facilities = () => {
  
  return (
    <section
      id="facilities"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          des="FACILITIES"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="FABRICATION SHOP 1"
          src={facilitytOne}
        />
        <ProjectsCard
          title="FABRICATION SHOP 2"
          src={facilityTwo}
        />
        <ProjectsCard
          title="MACHINE SHOP , WELDING & CUTTING FACILITY 1"
          src={facilityThree}
        />
        <ProjectsCard
          title="MACHINE SHOP , WELDING & CUTTING FACILITY 2"
          src={facilityFour}
        />
        <ProjectsCard
          title="MACHINE SHOP , WELDING & CUTTING FACILITY 3"
          src={facilityFive}
        />
        <ProjectsCard
          title="MACHINE SHOP , WELDING & CUTTING FACILITY 4"
           src={facilitySix}
        />
      </div>
    </section>
  );
}

export default Facilities